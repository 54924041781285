<template>
	<v-popover
		:placement="placement"
		popover-inner-class="m-0"
		popover-class="table-popover"
		:popover-arrow-class="[{ 'd-none': !showArrow }]"
		:disabled="disabled"
		:open="open"
		@hide="$emit('hide')"
		:boundaries-element="boundariesElement"
	>
		<div class="d-flex align-items-center">
			<slot name="title" />
		</div>
		<template slot="popover">
			<slot />
			<div
				class="d-flex close-button justify-content-center align-items-center"
				aria-label="Close"
				v-close-popover
			>
				<i class="fas fa-times text-bold" />
			</div>
		</template>
	</v-popover>
</template>

<script>
export default {
	name: 'AqTablePopover',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		boundariesElement: {
			type: String,
			required: false,
			default: null,
		},
		showArrow: {
			type: Boolean,
			default: true,
		},
		placement: {
			type: String,
			default: 'top',
		},
	},
};
</script>

<style lang="scss">
.table-popover {
  table {
    background: var(--bodyBg);
    border: 3px solid var(--fastTrackBarColor);

    td,
    th {
      border: 1px solid var(--userIcon);
    }

    th {
      background: var(--fastTrackBarColor);
      color: var(--userIcon);
      padding: 5px 10px;
    }

    tbody {
      td {
        padding: 0 10px;
      }

      tr {
        height: 12px;
      }
    }
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -7px;
    background: var(--userIcon) 0% 0% no-repeat padding-box;
    border: 1px solid var(--cardIconSVG);
    color: var(--logoBg);
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
}
</style>
