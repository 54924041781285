import querystring from 'querystring';
import httpClient from '@commonServices/httpClient';
import { toLetterView } from '@commonServices/utils/lettersConverter';
import { FilterType } from '@commonServices/models/FilterType';
import extraService from '@commonServices/extraService';

const toFilterParams = ({ letterType, dateFrom, dateTo, claimStatus, recipientType, zippedFileStatus, brandId }) => {
	const params = {};
	if (letterType) {
		params.letterType = letterType;
	}
	if (dateFrom) {
		params.dateFrom = dateFrom.toISOString();
	}
	if (dateTo) {
		params.dateTo = dateTo.toISOString();
	}
	if (claimStatus) {
		params.claimStatus = claimStatus;
	}
	if (recipientType) {
		params.recipientType = recipientType;
	}
	if (zippedFileStatus) {
		params.zippedFileStatus = zippedFileStatus;
	}
	if (brandId) {
		params.brandId = brandId;
	}
	return params;
};

export default {
	getPendingLettersCount () {
		return httpClient('/api/claims/letters/pending/count')
			.get();
	},
	getZippedLettersCount () {
		return httpClient('/api/claims/letters/zipped/count')
			.get();
	},
	filterLetters (filtersPayload, paging) {
		const params = { ...paging.toQueryObject(), ...toFilterParams(filtersPayload) };

		return httpClient(`/api/claims/letters/filter?${querystring.stringify(params)}`)
			.get()
			.then(toLetterView);
	},
	zipLetters (selection, filtersPayload) {
		const params = toFilterParams(filtersPayload);
		return httpClient(`/api/claims/letters/zip?${querystring.stringify(params)}`)
			.post(selection);
	},
	getMenuOptions () {
		return httpClient('/api/claims/letters/menu-options')
			.get();
	},
	getUserFilters (filterSetId) {
		return httpClient(`/api/claims/letters/filters/${filterSetId}`)
			.get();
	},
	saveUserFilter (userFilterModel, handleErrorCodes = []) {
		return httpClient('/api/claims/letters/filters', { handleErrorCodes })
			.post(userFilterModel);
	},
	updateUserFilterSet (filterSetId, filters) {
		return httpClient(`/api/claims/letters/filters/${filterSetId}`)
			.put(filters);
	},
	deleteUserFilterSet (filterSetId) {
		return httpClient(`/api/claims/letters/filters/${filterSetId}`)
			.delete();
	},
	getFiltersLookupData () {
		const filterTypes = [FilterType.Brand, FilterType.ClaimStatus, FilterType.LetterRecipient];

		return extraService.getData(filterTypes);
	},
};
