<template>
	<div class="info-banner d-flex align-items-center rounded py-5 px-15">
		<div class="circle mr-4 text-primary">
			<i
				class="fs-20"
				:class="iconClass"
			/>
		</div>
		<div class="d-flex ml-10 d-inline-block">
			<slot />
		</div>
	</div>
</template>

<script>

export default {
	name: 'AqStepItem',
	props: {
		iconClass: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
  .info-banner {
    background: var(--cardIconBg);
    line-height: 30px;

    .circle i {
      line-height: 28px;
    }
  }

</style>
