import ClaimItemSummaryView from '@commonServices/models/ClaimItemSummaryView';
import ParentPetClaim from '@commonServices/models/ParentPetClaim';
import ClaimBasicView from '@commonServices/models/ClaimBasicView';
import ClaimItemView from '@commonServices/models/ClaimItemView';
import ClaimItemDeduction from '@commonServices/models/ClaimItemDeduction';
import ClaimItemInput from '@commonServices/models/ClaimItemInput';
import ClaimMissingItem from '@commonServices/models/ClaimMissingItem';
import { longDate, claimIdFormat, shortDate, currency } from '@commonServices/utils/filters';
import { ClaimDataItem } from '@commonServices/models/ClaimDataItem';
import TreatmentPeriod from '@commonServices/models/TreatmentPeriod';
import PolicySectionGraph from '@commonServices/models/PolicySectionGraph';
import ClaimGraphSegment from '@commonServices/models/ClaimGraphSegment';
import GeneralScanModel from '@commonServices/models/GeneralScanModel';
import VetInvoiceInput from '@commonServices/models/VetIvoiceInput';
import PaymentTransaction from '@commonServices/models/PaymentTransaction';
import PaymentInstruction from '@commonServices/models/PaymentInstruction';
import PaymentDetails from '@commonServices/models/PaymentDetails';
import { claimItemsValidationResultToPageStatusMap } from '@commonServices/models/PageStatusEnum';
import groupBy from 'lodash.groupby';
import NotificationItem from '@commonServices/models/notifications/NotificationItem';
import NotificationUser from '@commonServices/models/notifications/NotificationUser';
import PreExistingConditionView from '@commonServices/models/PreExistingConditionView';
import EligibleConditionView from '@commonServices/models/EligibleConditionView';
import RelatedCondition from '@commonServices/models/RelatedCondition';
import { ExclusionTypeEnumMap } from '@commonServices/models/ExclusionTypeEnum';
import { EligibleConditionTypeEnumMap } from '@commonServices/models/EligibleConditionTypeEnum';
import { claimNumberStatus, claimStatuses, finalClaimStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import { toApiDateFilter, fromStringToDate, isBothSameDay, addDays, areDatesEqual, diffInMonths, getDayStart } from '@commonServices/utils/dateUtils';
import Note from '@commonServices/models/Note';
import LocalDate from '@commonServices/models/LocalDate';
import {
	NotificationDescription,
	NotificationMessage,
} from '@commonServices/models/NotificationType';
import RecipientType from '@commonServices/models/RecipientType';
import CustomerView from '@commonServices/models/CustomerView';
import PetView from '@commonServices/models/PetView';
import PolicyTermView from '@commonServices/models/PolicyTermView';
import PetVetSearchView from '@commonServices/models/PetVetSearchView';
import { PayeeType, PaymentType, PaymentTypeDisplayMap } from '@commonServices/utils/payeeOptions';
import { PaymentMethodDisplayMap } from '@commonServices/models/PaymentMethod';
import ClaimPetView from '@commonServices/models/ClaimPetView';
import { PolicyStatusDisplay } from '@commonServices/models/PolicyStatus';
import WaitPeriodView from '@commonServices/models/WaitPeriodView';

export function toClaimItemSummaryView (claimItemsSummary) {
	const claimItems =	claimItemsSummary.map((item) => {
		const userDeductions = item.deductions.filter((ded) => !ded.isSystem);
		const systemDeductions = item.deductions.filter((ded) => ded.isSystem);

		const userDeductionsAmount = userDeductions.reduce((sum, ded) => {
			return sum + ded.amount;
		}, 0);
		const systemDeductionsAmount = systemDeductions.reduce((sum, ded) => {
			return sum + ded.amount;
		}, 0);

		const totalDeductions = {
			totalUser: {
				deductedValue: userDeductionsAmount,
			},
			totalExcess: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'FixedExcessDeduction'),
			},
			totalCoInsurance: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'CoInsuranceDeduction'),
				originalLimit: getDeductionOriginalLimit(systemDeductions, 'CoInsuranceDeduction'),
			},
			totalPartialCoverage: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'PartialCoverageDeduction'),
				originalLimit: getDeductionOriginalLimit(systemDeductions, 'PartialCoverageDeduction'),
			},
			totalNonFinancialLimit: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'NonFinancialLimitDeduction'),
			},
			totalLimit: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'SectionLimitDeduction'),
			},
			totalConditionLimit: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'ConditionLimitDeduction'),
			},
			totalNoCoverage: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'NoCoverDeduction'),
			},
			totalRejected: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'RejectedDeduction'),
			},
			totalTax: {
				deductedValue: summarizeSystemDeductions(systemDeductions, 'TaxDeduction'),
			},
		};

		const claimItemSummaryView = new ClaimItemSummaryView(
			item.policySectionId,
			item.policySectionName,
			new Date(item.treatmentStartDate),
			new Date(item.treatmentEndDate),
			item.amount,
			userDeductionsAmount,
			systemDeductionsAmount,
			item.totalAmount,
			totalDeductions,
		);
		return claimItemSummaryView;
	});

	return claimItems;
}

export function toParentPetClaim (parentPetClaims) {
	const petClaims = [];
	for (let i = 0; i < parentPetClaims.length; i++) {
		const claim = parentPetClaims[i];
		for (let index = 0; index < claim.conditions.length; index++) {
			const condition = claim.conditions[index];
			const parentClaim = new ParentPetClaim(
				claim.id,
				condition.id,
				fromStringToDate(claim.dateOfLoss),
				fromStringToDate(claim.dateOfDeath),
				fromStringToDate(claim.treatmentStart),
				fromStringToDate(claim.treatmentEnd),
				claim.amount,
				claim.canBeParent,
				condition.ailment,
				condition.conditionType,
				condition.bodyPart,
				claim.formType,
			);
			petClaims.push(parentClaim);
		}
	}
	return petClaims;
}

export function toClaimMissingItems (claimMissingItemInputs, rejectionData, reassessmentOutcomeId, fraudCheck, fraudCheckReasons, fraudCheckComment) {
	const claimMissingItems = claimMissingItemInputs.map((item) => {
		return new ClaimMissingItem(
			item.claimMissingItemId,
			item.missingItemId,
			item.contactId,
			item.contactType,
			item.comment,
		);
	});
	return {
		claimMissingItems,
		rejectionData,
		reassessmentOutcomeId,
		fraudCheck,
		fraudCheckReasons,
		fraudCheckComment,
	};
}

export function toPaymentDetails (paymentDetails) {
	return new PaymentDetails(
		paymentDetails.netClaimAmount,
		paymentDetails.requireExGratiaPayment,
		paymentDetails.payeeTypes,
	);
}

export function toClaimBasicView (claimData) {
	const basicData = {
		description: claimData.fnolDescription,
		dateOfLoss: fromStringToDate(claimData.dateOfLoss),
		dateOfDeath: fromStringToDate(claimData.dateOfDeath),
		treatmentStart: fromStringToDate(claimData.treatmentStartDate),
		treatmentEnd: fromStringToDate(claimData.treatmentEndDate),
		amount: claimData.amount || 0,
	};
	const conditions = claimData.conditions.map(c => toClaimCondition(c));

	return new ClaimBasicView(
		claimData.claimId,
		claimData.petId,
		toClaimPetView(claimData.pet),
		claimData.policyId,
		basicData,
		conditions,
		claimData.vets,
		claimData.triggeredRequest,
		claimData.hasMissingItems,
		claimData.hasClaimItems,
		claimData.hasPayments,
		claimData.wasReassessed,
		claimData.isParent,
		claimData.wasIgnoredNoCoverPeriod,
		claimData.wasIgnoredWaitingPeriod,
		claimData.wasIgnoredPreExistingCondition,
		claimData.wasIgnoredNoProductCoverage,
		claimData.wasIgnoredDuplicateClaim,
		claimData.wasIgnoredDuplicateInvoice,
		claimData.duplicateClaimIds,
		claimData.formType,
		claimData.preAuthorisationCompleted,
		claimData.payeeTypes,
		claimData.isFastTrackReviewed,
		claimData.claimSource,
		claimData.createdDateTime,
		toUser(claimData.createdByUser),
		claimData.closedDateTime,
		toUser(claimData.closedByUser),
		toClaimAssignment(claimData.claimAssignment),
		claimData.reassessmentDateTime,
		toUser(claimData.reassessmentCreatedByUser),
		claimData.reassessmentReason,
		claimData.status,
		claimData.nextStatus,
		claimData.invoiceNumbers,
		claimData.continuation,
		claimData.allowRestoring,
		claimData.failedRestoreReason,
		claimData.hospitalNotificationEnabled,
		claimData.hospitalNotificationGenerated,
		claimData.billingSyncEnabled,
		claimData.multiSubmissionClaimIds,
		claimData.hasPreAuthCopy,
		claimData.isEscalated,
		claimData.escalationType,
	);
}
export function toClaimPetView (claimPet) {
	return new ClaimPetView(
		claimPet.id,
		claimPet.photo,
		claimPet.name,
		claimPet.gender,
		claimPet.color,
		claimPet.purchasePrice,
		claimPet.microchipNumber,
		claimPet.isPreExistingConditions,
		claimPet.type,
		claimPet.breedName,
		claimPet.registeredVets.map(toPetVetSearchView),
	);
}
export function toClaimCondition (condition) {
	return {
		id: condition.id,
		ailment: condition.ailment,
		conditionType: condition.conditionType,
		bodyPart: condition.bodyPart,
		parentConditionId: condition.parentConditionId,
		parentClaimId: condition.parentClaimId,
		parentClaimDateOfLoss: condition.parentClaimDateOfLoss,
		wasIgnoredPreExistingCondition: condition.wasIgnoredPreExistingCondition,
		wasIgnoredNoProductCoverage: condition.wasIgnoredNoProductCoverage,
		wasIgnoredNoCoverPeriod: condition.wasIgnoredNoCoverPeriod,
		wasIgnoredWaitingPeriod: condition.wasIgnoredWaitingPeriod,
		isRejected: condition.isRejected,
		isParent: condition.isParent,
		state: condition.state,
		rejectionData: condition.manualRejection
			? {
				isManuallyRejected: true,
				rejectionReasonId: condition.manualRejection.rejectionReasonId,
				comment: condition.manualRejection.comment,
				rejectionReasonDescription: condition.manualRejection.rejectionReasonDescription,
			}
			: {
				isManuallyRejected: false,
				rejectionReasonId: 0,
				rejectionReasonDescription: null,
				comment: null,
			},
		eligibleConditionId: condition.eligibleConditionId,
		waitingPeriodEndDate: condition.waitingPeriodEndDate,
	};
}

export function toClaimItemView (claimItem) {
	return new ClaimItemView(
		claimItem.claimItemId,
		claimItem.claimConditionId,
		claimItem.policySectionId,
		claimItem.policySectionDescription,
		toTreatmentPeriod(claimItem.treatmentPeriod),
		claimItem.amount,
		claimItem.amountExpression,
		toUserDeductionsDecimal(claimItem.userDeductions || []),
		Object.entries(claimItem.systemDeductions).map(([key, value]) => ({	key, value })),
		claimItem.total,
		claimItem.fileMetadataId,
		claimItem.isValid,
		claimItem.nonFinancialNumber,
		claimItem.rebalanceExcess,
		claimItem.taxAmount,
		claimItem.claimItemDeductions.map(cit => toClaimItemDeduction(cit)),
	);
}

export function fromScannedtoClaimItemView (scannedItem) {
	return new ClaimItemView(
		scannedItem.claimItemId,
		scannedItem.claimConditionId,
		scannedItem.policySectionId,
		scannedItem.policySectionDescription,
		toTreatmentPeriod(scannedItem.treatmentPeriod),
		scannedItem.amount,
		scannedItem.amountExpression,
		scannedItem.userDeductions,
		undefined,
		scannedItem.totalAmount,
		scannedItem.fileMetadataId,
		true,
		scannedItem.nonFinancialNumber,
		false,
		scannedItem.taxAmount,
		null,
	);
}

export function toClaimItemInput (claimItemView) {
	const treatmentPeriod = {
		...claimItemView.treatmentPeriod,
		start: new LocalDate(claimItemView.treatmentPeriod.start),
		end: new LocalDate(claimItemView.treatmentPeriod.end),
	};

	return new ClaimItemInput(
		claimItemView.claimItemId,
		claimItemView.claimConditionId,
		claimItemView.policySectionId,
		claimItemView.policySectionName,
		claimItemView.amount,
		claimItemView.amountExpression,
		claimItemView.totalAmount,
		treatmentPeriod,
		claimItemView.userDeductions,
		claimItemView.fileId,
		claimItemView.isValid,
		claimItemView.nonFinancialNumber,
		claimItemView.rebalanceExcess,
		claimItemView.taxAmount,
	);
}

export function toTreatmentPeriod (data) {
	return new TreatmentPeriod(
		data.treatmentPeriodType,
		fromStringToDate(data.start),
		fromStringToDate(data.end),
	);
}

export function toPolicyDates (data) {
	return {
		treatmentStartDate: fromStringToDate(data.treatmentStartDate),
		treatmentEndDate: fromStringToDate(data.treatmentEndDate),
		policyTerms: data.policyTerms,
	};
}

export function toClaimItemsWithStatus (data) {
	return {
		claimItems: data.claimItems.map(x => toClaimItemView(x)),
		status: claimItemsValidationResultToPageStatusMap[data.validationResult],
		validationResult: data.validationResult,
		hasPayments: data.hasPayments,
		claimAmount: data.claimAmount || 0,
		wasIgnoredNoPolicyLimits: data.wasIgnoredNoPolicyLimits,
		wasIgnoredDuplicateInvoice: data.wasIgnoredDuplicateInvoice,
		wasIgnoredDuplicateClaim: data.wasIgnoredDuplicateClaim,
		duplicateClaimIds: data.duplicateClaimIds,
	};
}

export function summarizeSystemDeductions (systemDeductions, deductionType) {
	return systemDeductions.filter((ded) => ded.description === deductionType)
		.reduce((sum, ded) => {
			return sum + ded.amount;
		}, 0);
}

function getDeductionOriginalLimit (systemDeductions, deductionType) {
	return systemDeductions.filter((ded) => ded.description === deductionType)[0]?.originalLimit ?? null;
}

export function toPetClaimsData (claimsRawData) {
	const sortedClaims = claimsRawData.map(c =>
		({
			...c,
			dateOfLoss: fromStringToDate(c.dateOfLoss),
			treatmentEnd: fromStringToDate(c.treatmentEnd),
			treatmentStart: fromStringToDate(c.treatmentStart),
			assessedDateTime: fromStringToDate(c.assessedDateTime),
			closedDateTime: fromStringToDate(c.closedDateTime),
			diagnosis: c.conditions.map(c => c.ailment?.firstCause).filter(Boolean).join('; '),
		})).sort((a, b) => a.treatmentStart - b.treatmentStart);

	const plainClaimsList = sortedClaims.map(c => getClaimDataItem(c, false));
	const plainConditionsClaims = sortedClaims.reduce((acc, claim) => {
		const spreadClaims = claim.conditions.length
			? claim.conditions.map((condition) => ({
				...claim,
				condition,
			}))
			: [{ ...claim, condition: { parentConditionId: null } }];
		return [...acc, ...spreadClaims];
	}, []);
	const groupClaims = groupBy(plainConditionsClaims, function (claim) {
		return claim.condition.parentConditionId;
	});
	const parentClaims = groupClaims.null;
	if (!parentClaims) {
		return { groupedClaims: [], plainClaimsList: [] };
	}

	const groupedClaims = parentClaims.map((item) => {
		const claimMainItem = getClaimDataItem(item, true);
		let groupItems = [];
		groupItems.push(claimMainItem);
		const childClaims = groupClaims[item.condition.id];
		if (childClaims) {
			childClaims.map((child) => groupItems.push(getClaimDataItem(child, true)));
		}

		groupItems = removeDuplicatesFromClaims(groupItems);

		return {
			id: claimMainItem.claimConditionId,
			diagnosis: claimMainItem.diagnosis,
			dateOfLoss: item.dateOfLoss,
			continuationClaimId: item.id,
			continuationClaimConditionId: item.condition.id,
			continuationClaimStatus: claimMainItem.displayStatus,
			claims: groupItems,
			linkedClaims: item.linkedClaims,
		};
	});

	return { groupedClaims, plainClaimsList };
}

function removeDuplicatesFromClaims (items) {
	return items.reduce((acc, current) => {
		const x = acc.find(item => item.claimId === current.claimId);
		if (!x) {
			return acc.concat([current]);
		} else {
			return acc;
		}
	}, []);
}

function toClaimItemDeduction (claimItemDeduction) {
	return new ClaimItemDeduction(
		claimItemDeduction.deductionId,
		claimItemDeduction.deductionType,
		claimItemDeduction.originalLimit,
	);
}

function getClaimDataItem (claimData, groupView) {
	let diagnosis = claimData.conditions.map(c => c.ailment?.firstCause).filter(Boolean).join('; ');
	let amount = claimData.amount || 0;
	let paid = claimData.paid;
	let claimConditionId = claimData.conditions[0].id;
	const historical = finalClaimStatuses.includes(claimData.status) && diffInMonths(getDayStart(), claimData.assessedDateTime || claimData.closedDateTime) >= 24;

	if (groupView) {
		diagnosis = claimData.condition.ailment?.firstCause;
		amount = claimData.condition.amount || 0;
		paid = claimData.condition.paid || 0;
		claimConditionId = claimData.condition.id;
	}

	return new ClaimDataItem(
		claimData.id,
		claimConditionId,
		claimData.treatmentStart,
		claimData.treatmentEnd,
		diagnosis,
		amount,
		paid,
		toClaimDisplayStatus(claimData.status),
		claimData.status,
		claimData.reassessmentDateTime,
		claimData.reassessmentReason,
		claimData.reassessmentReasonCode,
		claimData.reassessed,
		claimData.conditions,
		claimData.createdDateTime,
		toUser(claimData.closedByUser),
		claimData.closedDateTime,
		toUser(claimData.assignedToUser),
		claimData.assignedToDateTime,
		toUser(claimData.assessedByUser),
		claimData.assessedDateTime,
		claimData.claimVets,
		claimData.formType,
		claimData.nextStatus,
		claimData.linkedClaims,
		claimData.claimSource,
		claimData.fnolDescription,
		claimData.payments,
		claimData.invoices,
		claimData.continuation,
		historical,
	);
}

export function toClaimDisplayStatus (index) {
	return claimStatuses[index].description;
}

export function toInteractionsApiFilters (selectedFilter) {
	const appliedFilter = {
		statuses: selectedFilter.statuses.map(stat => stat.key),
		users: selectedFilter.users.map(user => user.id),
		communications: selectedFilter.communications.map(comm => comm.key),
	};

	if (selectedFilter.date.type) {
		const { dateFrom, dateTo } = toApiDateFilter(selectedFilter.date.type, selectedFilter.date.dateFrom, selectedFilter.date.dateTo);
		appliedFilter.dateFrom = dateFrom;
		appliedFilter.dateTo = dateTo;
	}
	return appliedFilter;
}

export function toNotesApiFilters (selectedFilter) {
	const appliedFilter = {
		users: selectedFilter.users.map(user => user.id),
	};

	if (selectedFilter.type) {
		appliedFilter.type = selectedFilter.type.key;
	}

	if (selectedFilter.date.type) {
		const { dateFrom, dateTo } = toApiDateFilter(selectedFilter.date.type, selectedFilter.date.dateFrom, selectedFilter.date.dateTo);
		appliedFilter.dateFrom = dateFrom;
		appliedFilter.dateTo = dateTo;
	}
	return appliedFilter;
}

function toUserDeductionsDecimal (userDeductions) {
	return userDeductions.map(deduction => {
		return {
			description: deduction.description,
			id: deduction.id,
			amount: deduction.amount,
			amountExpression: deduction.amountExpression,
		};
	});
}

export function toClaimPaymentRecipients (recipients) {
	return recipients.map(recipient => {
		return {
			accounts: recipient.accounts,
			claimPayment: recipient.claimPayment,
			exGratiaClaimPayment: recipient.exGratiaClaimPayment,
			id: recipient.id,
			name: recipient.name,
			recipientType: recipient.recipientType,
			isEditable: recipient.isEditable,
		};
	});
}

export function toPaymentTransactions (paymentTransactions) {
	return paymentTransactions.map((paymentTransaction) =>
		new PaymentTransaction(
			paymentTransaction.claimPaymentId,
			paymentTransaction.paymentAccountId,
			paymentTransaction.payee,
			paymentTransaction.paymentType,
			paymentTransaction.paymentStatus,
			paymentTransaction.paymentMethod,
			paymentTransaction.paymentAccountName,
			paymentTransaction.grossAmount,
			toPaymentInstructions(paymentTransaction.instructions ?? []),
		),
	);
}

function toPaymentInstructions (instructions) {
	return instructions.map((instruction) => {
		return new PaymentInstruction(
			instruction.id,
			instruction.paymentIssueDate,
			instruction.payeeName,
			[
				instruction.address1,
				instruction.address2,
				instruction.city,
				instruction.region,
				instruction.country,
			].filter(Boolean).join(', '),
			instruction.paymentMethod,
			instruction.batchDateTime,
			instruction.transactionId || 'N/A',
			instruction.status,
		);
	});
}

export function toPolicySectionGraphs (graphs) {
	return graphs.map((graph) => {
		return new PolicySectionGraph(
			graph.id,
			graph.description,
			graph.type,
			graph.level,
			graph.limitType,
			graph.productType,
			graph.productLevel,
			graph.sectionLimit,
			graph.noMaximumLimit,
			toClaimGraphSegments(graph.segments),
			graph.sectionLimitEndsDate ? new Date(graph.sectionLimitEndsDate) : null,
			graph.unit,
		);
	});
}

function toClaimGraphSegments (segments) {
	return segments.map((segment) => {
		return new ClaimGraphSegment(
			segment.claimId,
			segment.claimConditionId,
			segment.netAmount,
			segment.nonFinancialNumber,
		);
	});
}

export function toPolicyTerms (terms) {
	return terms.map((term) => {
		return {
			policyTermId: term.policyTermId,
			policyTermStartDate: term.policyTermStartDate,
			policyTermEndDate: term.policyTermEndDate,
			yearStartDate: term.yearStartDate,
			yearEndDate: term.yearEndDate,
			content: `${longDate(term.policyTermStartDate)} - ${longDate(term.policyTermEndDate)}`,
			product: term.product,
			addons: term.addons,
		};
	}).sort((a, b) => {
		return new Date(a.endDate) - new Date(b.endDate);
	});
}

export function toPetViewPolicyTerms (terms) {
	const groupedPolicyTerms = groupBy(terms, term => term.product.type);
	const sortedEntries = Object.entries(groupedPolicyTerms).sort(([key1], [key2]) => key1.localeCompare(key2));
	return sortedEntries.map(([key, items]) => ({
		group: key,
		policyTerms: items,
	}));
}

function getDefaultScanModel (fieldName, defaultValue = null) {
	return ({ fieldName, key: fieldName, originalValue: null, value: defaultValue, accuracy: 1, placement: null });
}

function getScanModel (inputModel, valueFormat = x => x) {
	return {
		fieldName: inputModel.fieldName,
		key: inputModel.key,
		originalValue: inputModel.originalValue,
		value: valueFormat(inputModel.value),
		accuracy: inputModel.confidence,
		placement: inputModel.placement,
	};
}

export function toInvoiceScanItem (scanItemMap) {
	return {
		date: scanItemMap.Date ? getScanModel(scanItemMap.Date) : getDefaultScanModel('Date'),
		quantity: scanItemMap.Qty ? getScanModel(scanItemMap.Qty) : getDefaultScanModel('Qty'),
		description: scanItemMap.Description ? getScanModel(scanItemMap.Description) : getDefaultScanModel('Description'),
		amount: scanItemMap.Amount ? getScanModel(scanItemMap.Amount) : getDefaultScanModel('Amount'),
		policySection: scanItemMap.PolicySection ? getScanModel(scanItemMap.PolicySection) : getDefaultScanModel('PolicySection'),
		excluded: scanItemMap.Excluded ? getScanModel(scanItemMap.Excluded) : getDefaultScanModel('Excluded'),
		deduction: scanItemMap.Deduction ? getScanModel(scanItemMap.Deduction) : getDefaultScanModel('Deduction'),
		editedByUser: scanItemMap.EditedByUser ? getScanModel(scanItemMap.EditedByUser) : getDefaultScanModel('EditedByUser'),
		createdByUser: scanItemMap.CreatedByUser ? getScanModel(scanItemMap.CreatedByUser) : getDefaultScanModel('CreatedByUser'),
		claimCondition: scanItemMap.ClaimCondition ? getScanModel(scanItemMap.ClaimCondition) : getDefaultScanModel('ClaimCondition'),
		nonFinancialNumber: scanItemMap.NonFinancialNumber ? getScanModel(scanItemMap.NonFinancialNumber) : getDefaultScanModel('NonFinancialNumber'),
		discountAmount: scanItemMap.DiscountAmount ? getScanModel(scanItemMap.DiscountAmount) : getDefaultScanModel('DiscountAmount'),
		discountPercent: scanItemMap.DiscountPercent ? getScanModel(scanItemMap.DiscountPercent) : getDefaultScanModel('DiscountPercent'),
		itemType: scanItemMap.ItemType ? getScanModel(scanItemMap.ItemType) : getDefaultScanModel('ItemType', 0),
		tax: scanItemMap.Tax ? getScanModel(scanItemMap.Tax) : getDefaultScanModel('Tax'),
		petName: scanItemMap.PetName ? getScanModel(scanItemMap.PetName) : getDefaultScanModel('PetName'),
	};
}

function toGeneralScanModel (data) {
	return new GeneralScanModel(
		data.fieldName,
		data.key,
		data.originalValue,
		data.value,
		data.accuracy,
		data.placement,
	);
}
export function toInvoiceScan (scanData) {
	const result = { tables: [], keyValuePairs: [] };
	if (scanData.total && Object.keys(scanData.total).length !== 0) {
		result.keyValuePairs.push(toGeneralScanModel(scanData.total));
	}
	if (scanData.items) {
		scanData.items.forEach(x => {
			const row = [];
			for (const prop in x) {
				let field = null;
				if (prop === 'deduction') {
					field = toGeneralScanModel(getDefaultScanModel('Deduction'));
					field.value = x[prop].value;
				} else {
					field = toGeneralScanModel(x[prop]);
				}
				row.push(field);
			}
			result.tables.push(row);
		});
	}
	return result;
}
export function toVetInvoiceModel (fileId, scanData) {
	const result = {
		fileMetadataId: fileId,
		totalTaxAmount: scanData.totalTax?.value,
		taxesIncluded: scanData.taxesIncluded?.value,
		rows: [],
	};
	if (scanData.items) {
		result.rows = scanData.items.filter(item => !item.excluded.value)
			.map(x =>
				new VetInvoiceInput(
					new LocalDate(fromStringToDate(x.date.value)),
					parseFloat(x.amount.value),
					x.policySection.value.id,
					x.deductions?.map(deduction => {
						return {
							id: deduction.value.id,
							amount: deduction.value.amount,
						};
					}) ?? [],
					x.claimCondition.value.id,
					x.nonFinancialNumber?.value,
					parseFloat(x.tax.value),
					parseFloat(x.discountAmount.value),
					x.itemType?.value ?? 0,
				));
	}
	return result;
}

const dateFilters = [
	{
		dateDescription: 'Today',
		getFilterFunction: () => item => isBothSameDay(item.createdDate, new Date()),
	},
	{
		dateDescription: 'Older',
		getFilterFunction: () => item => !isBothSameDay(item.createdDate, new Date()),
	},
];

export function toNotificationItems (notifications) {
	const notificationItems = notifications.map((notification) => {
		return new NotificationItem(
			notification.id,
			notification.claimId,
			toNotificationMessage(notification.type, notification.claimId),
			toNotificationComment(notification.type),
			notification.comment,
			notification.viewed,
			notification.createdDateTime,
			new NotificationUser(
				notification.createdBy.id,
				notification.createdBy.firstName,
				notification.createdBy.lastName,
			),
			notification.customerId,
		);
	});
	return dateFilters.map((filter) =>
		({
			dateDescription: filter.dateDescription,
			notificationItems: notificationItems.filter(filter.getFilterFunction())
				.sort((item1, item2) => {
					return item2.createdDate - item1.createdDate;
				}),
		}),
	);
}

export function toFullAddress (address) {
	return [
		address.address1,
		address.address2,
		address.address3,
		address.townCity,
		address.regionCounty,
		address.postalCode,
		address.country,
	].filter(Boolean).join(', ');
}

export function toShortAddress (address) {
	return [
		address.regionCounty,
		address.country,
	].filter(Boolean).join(', ');
}

function toNotificationMessage (notificationType, claimId) {
	const claimIdFormatted = claimIdFormat(claimId);
	return NotificationMessage[notificationType](claimIdFormatted);
}

function toNotificationComment (notificationType) {
	return NotificationDescription[notificationType];
}

export function toPreExistingConditions (conditionsData) {
	return conditionsData.map(toPreExistingConditionView);
}

export function toEligibleConditions (conditionsData) {
	return conditionsData.map(toEligibleConditionView);
}

export function toPreExistingConditionView (condition) {
	const exclusionTypeDisplay = ExclusionTypeEnumMap[condition.exclusionType];

	return new PreExistingConditionView(
		condition.id,
		condition.condition,
		condition.bodyPart,
		condition.exclusionType,
		exclusionTypeDisplay,
		fromStringToDate(condition.firstSignsDate),
		condition.createdBy,
		condition.createdDate,
		condition.updatedBy,
		condition.updatedDate,
		condition.comments,
		condition.relatedConditions.map(toRelatedCondition),
		condition.archived,
		condition.hasRejectedClaims,
		condition.vet,
		condition.documentCommentary,
		condition.diagnosisOccurrences,
		condition.isSystem,
		condition.pageNumber,
	);
}

export function toEligibleConditionView (condition) {
	const eligibleConditionTypeDisplay = EligibleConditionTypeEnumMap[condition.eligibleConditionType];

	return new EligibleConditionView(
		condition.id,
		condition.condition,
		condition.bodyPart,
		condition.eligibleConditionType,
		eligibleConditionTypeDisplay,
		fromStringToDate(condition.assessmentDate),
		condition.createdBy,
		condition.createdDate,
		condition.updatedBy,
		condition.updatedDate,
		condition.comments,
		condition.relatedConditions.map(toRelatedCondition),
		condition.archived,
		condition.hasRejectedClaims,
		condition.vet,
		condition.documentCommentary,
	);
}

function toRelatedCondition (relatedCondition) {
	return new RelatedCondition(
		relatedCondition.condition,
		relatedCondition.bodyPart,
	);
}

export function toNotes (notesData) {
	return notesData.map((note) =>
		new Note(
			note.id,
			note.title,
			note.details,
			note.type,
			note.customerId,
			note.petId,
			note.claimId,
			note.createdBy,
			note.createdDate,
			note.updatedBy,
			note.updatedDate,
			note.subtype,
		),
	);
}

export function toUser (user) {
	if (user == null) return null;
	return {
		...user,
		description: `${user.firstName} ${user.lastName}`,
	};
}

export function toClaimAssignment (claimAssignment) {
	if (claimAssignment == null) return null;
	return {
		user: toUser(claimAssignment.user),
		assignmentDateTime: claimAssignment.assignmentDateTime,
		isPrioritized: claimAssignment.isPrioritized,
	};
}

export function toClaimConditionDescription (claimCondition) {
	return [claimCondition.ailment?.firstCause, claimCondition.conditionType?.description, claimCondition.bodyPart?.description]
		.filter(Boolean)
		.join(' - ');
}

export function toRecipientLabel (recipientType, uiSettings, appSettings) {
	if (recipientType !== RecipientType.PolicyHolder) {
		return 'Vet';
	}
	if (appSettings && appSettings.payeeLabel) {
		return appSettings.payeeLabel;
	}
	return uiSettings.payeeLabel;
}

export function toDateOfLossLabel (appSettings) {
	if (appSettings && appSettings.dateOfLossLabel) {
		return appSettings.dateOfLossLabel;
	} else {
		return 'Date of Loss';
	}
}

export function toCustomerView (customer) {
	return new CustomerView(
		customer.id,
		customer.ref,
		customer.title,
		customer.firstName,
		customer.lastName,
		customer.email,
		customer.phoneNumber,
		customer.mobileNumber,
		customer.address,
		customer.brandId,
		customer.brandName,
		customer.brandLocale,
		customer.brandSuppressMissingInfo,
		customer.brandManualStopReissue,
		customer.pets.map(toPetView),
		customer.externalBrandId,
		customer.multicondition,
		customer.careFlag,
		customer.secondaryContacts.map(toCustomerView),
		customer.billingSyncEnabled,
		customer.customerCode,
		customer.communicationPreference,
		customer.language,
		customer.smsNotification,
	);
}

export function toPetView (pet) {
	return new PetView(
		pet.id,
		pet.ref,
		pet.gender,
		pet.name,
		pet.type,
		pet.breedName,
		fromStringToDate(pet.birthDate),
		fromStringToDate(pet.dateOfDeath),
		pet.color,
		pet.purchasePrice,
		pet.microchipNumber,
		pet.photo,
		pet.underwritingStatusId,
		pet.underwritingStatusDescription,
		fromStringToDate(pet.purchaseAdoptionDate),
		pet.neutered,
		pet.registeredVets,
		pet.hasPreEnrollmentDentalExam,
		pet.totalClaimsCount,
		pet.policyTerms.map(toPolicyTermView),
		pet.careFlag,
	);
}

export function toPetVetSearchView (petvet) {
	return new PetVetSearchView(
		petvet.isHistorical,
		fromStringToDate(petvet.assignmentDate),
		petvet.isEditable,
		petvet.approved,
		petvet.id,
		petvet.externalVetId,
		petvet.ref,
		petvet.practiceName,
		petvet.address1,
		petvet.address2,
		petvet.address3,
		petvet.townCity,
		petvet.postalCode,
		petvet.regionCounty,
		petvet.country,
		petvet.email,
		petvet.phoneNumber,
		petvet.careFlag,
		petvet.hospitalContactName,
		petvet.faxNumber,
		petvet.claimPaymentMethod,
		petvet.preferredContactMethod,
		petvet.languagePref,
		petvet.active,
		petvet.vetDirectPay);
}

export function toPolicyTermView (term) {
	return new PolicyTermView(
		term.policyId,
		term.policyReference,
		term.policyStatus,
		PolicyStatusDisplay[term.policyStatus],
		fromStringToDate(term.policyInceptionDate),
		term.addons,
		term.policyTermId,
		term.product,
		term.policyTermStatus,
		fromStringToDate(term.policyTermStartDate),
		fromStringToDate(term.policyTermEndDate),
		fromStringToDate(term.yearStartDate),
		fromStringToDate(term.yearEndDate),
		term.policyPromoCode,
		term.policyAssociateName,
		term.policyOfferType,
		term.policyOffer,
	);
};

export function toClaimVersions (versions, locale, parentLocalClaim) {
	return versions.map(version => {
		const basic = version.basic;
		const claimItems = version.process.claimItems;
		const payments = version.payments;
		const claim = { ...version };

		claim.basic = {
			...basic,
			dateOfLoss: shortDate(basic.dateOfLoss),
			dateOfDeath: shortDate(basic.dateOfDeath),
			treatmentStartDate: shortDate(basic.treatmentStartDate),
			treatmentEndDate: shortDate(basic.treatmentEndDate),
			amount: currency(basic.amount, locale),
		};

		claim.claimItems = claimItems.map(claimItem => {
			const systemDeductions = Object.entries(claimItem.systemDeductions).map(([key, value]) =>
				({
					description: key,
					amount: value,
				}),
			);
			const claimCondition = basic.conditions.find(c => c.id === claimItem.claimConditionId);

			return {
				...claimItem,
				claimConditionDescription: claimCondition == null ? 'No Ailment' : toClaimConditionDescription(claimCondition),
				treatmentPeriod: `${shortDate(claimItem.treatmentPeriod.start)} - ${shortDate(claimItem.treatmentPeriod.end)}`,
				amount: currency(claimItem.amount, locale),
				userDeductionsTotal: `-${currency(claimItem.userDeductions.reduce((acc, curr) => acc + curr.amount, 0), locale)}`,
				systemDeductions,
				systemDeductionsTotal: getSystemDeductionsTotal(systemDeductions, claimItem.rebalanceExcess, locale),
				totalAmount: currency(claimItem.totalAmount, locale),
				nonFinancialNumber: claimItem.nonFinancialNumber || 'N/A',
			};
		});

		if (payments) {
			const recipients = payments.paymentRecipients
				.filter(recipient => recipient.paymentType === PaymentType.Claim)
				.map(recipient => {
					return {
						...recipient,
						recipientType: recipient.recipientType === PayeeType.PolicyHolder ? 'Policy Holder' : 'Vet',
						paymentType: 'Claim',
						displayAmount: currency(recipient.amount, locale),
						paymentDetails: `${recipient.paymentAccount.payee} ${_getPaymentAccountDetails(recipient.paymentAccount)}`,
					};
				});

			let total = claimItems.reduce((acc, curr) => acc + curr.totalAmount, 0);
			const totalAmount = recipients.reduce((acc, curr) => acc + Number(curr.amount), 0);

			if (version.isParent) {
				total -= versions.filter(x => !x.isParent && x.status !== claimNumberStatus.PreAuthorisationCompleted)
					.reduce((acc, copy) => acc.concat(copy.payments.paymentRecipients), [])
					.reduce((acc, copyPayment) => acc + copyPayment.amount, 0);
			}

			claim.payments = {
				recipients,
				exGratiaPayments: payments.paymentRecipients
					.filter(recipient => recipient.paymentType === PaymentType.ExGratia)
					.map(recipient => {
						return {
							...recipient,
							recipientType: recipient.recipientType === PayeeType.PolicyHolder ? 'Policy Holder' : 'Vet',
							paymentType: 'Ex Gratia',
							displayAmount: currency(recipient.amount, locale),
							paymentDetails: `${recipient.paymentAccount.payee} ${_getPaymentAccountDetails(recipient.paymentAccount)}`,
						};
					}),
				transactions: payments.paymentTransactions
					.map(transaction => {
						return {
							...transaction,
							paymentMethod: PaymentMethodDisplayMap[transaction.paymentMethod],
							paymentType: PaymentTypeDisplayMap[transaction.paymentType],
							amount: currency(transaction.grossAmount, locale),
						};
					}),
				paymentBreakdown: {
					summary: payments.paymentBreakdown.map(breakdown => {
						const systemDeductions = breakdown.deductions.filter(deduction => deduction.isSystem);
						const systemDeductionAmount = systemDeductions.reduce((acc, curr) => acc + curr.amount, 0);
						const userDeductions = breakdown.deductions.filter(deduction => !deduction.isSystem);
						const userDeductionAmount = userDeductions.reduce((acc, curr) => acc + curr.amount, 0);
						const totalDeductionsAmount = breakdown.deductions.reduce((acc, curr) => acc + curr.amount, 0);

						return {
							...breakdown,
							treatmentPeriod: `${shortDate(breakdown.treatmentStartDate)} - ${shortDate(breakdown.treatmentEndDate)}`,
							amount: currency(breakdown.amount),
							userDeductionAmount,
							systemDeductionAmount,
							userDeductionAmountDisplay: currency(userDeductionAmount, locale),
							systemDeductionAmountDisplay: currency(systemDeductionAmount, locale),
							totalAmount: currency(breakdown.totalAmount, locale),
							totalExcess: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'FixedExcessDeduction'), locale),
							},
							totalCoInsurance: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'CoInsuranceDeduction'), locale),
								originalLimit: getDeductionOriginalLimit(systemDeductions, 'CoInsuranceDeduction'),
							},
							totalPartialCoverage: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'PartialCoverageDeduction'), locale),
								originalLimit: getDeductionOriginalLimit(systemDeductions, 'PartialCoverageDeduction'),
							},
							totalNonFinancialLimit: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'NonFinancialLimitDeduction'), locale),
							},
							totalLimit: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'SectionLimitDeduction'), locale),
							},
							totalConditionLimit: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'ConditionLimitDeduction'), locale),
							},
							totalNoCoverage: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'NoCoverDeduction'), locale),
							},
							totalRejected: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'RejectedDeduction'), locale),
							},
							totalTax: {
								deductedValue: currency(summarizeSystemDeductions(systemDeductions, 'TaxDeduction'), locale),
							},
							totalDeductions: currency(totalDeductionsAmount, locale),
						};
					}),
					amount: currency(payments.paymentBreakdown.reduce((acc, curr) => acc + curr.amount, 0), locale),
					totalAmount: currency(payments.paymentBreakdown.reduce((acc, curr) => acc + curr.totalAmount, 0), locale),
				},
				total: currency(total, locale),
				totalAmount: currency(totalAmount, locale),
				remaining: currency(total - totalAmount, locale),
			};
		}

		if (claim.payments) {
			claim.payments.paymentBreakdown.totalUserAmount = currency(claim.payments.paymentBreakdown.summary.reduce((acc, curr) => acc + curr.userDeductionAmount, 0), locale);
			claim.payments.paymentBreakdown.totalSystemAmount = currency(claim.payments.paymentBreakdown.summary.reduce((acc, curr) => acc + curr.systemDeductionAmount, 0), locale);
		}

		if (claim.isParent) {
			_updateParentClaimWithLocalChanges(claim, parentLocalClaim, locale);
		}
		return claim;
	});
}

export function addEndDateToWaitingPeriods (waitingPeriods, policyInceptionDate) {
	return waitingPeriods?.map(wp => ({ ...wp, endDate: addDays(fromStringToDate(policyInceptionDate), wp.waitAmountDays <= 1 ? 0 : wp.waitAmountDays - 1) }));
}

export function toWaitingPeriodsView (waitingPeriods) {
	return Object.entries(groupBy(waitingPeriods, 'conditionType')).map(group => {
		const waitingPeriods = group[1];
		const productWaitingPeriod = waitingPeriods.find(wp => !wp.policyId);
		const overrideWaitingPeriod = waitingPeriods.find(wp => wp.policyId && !areDatesEqual(wp.endDate, productWaitingPeriod.endDate));
		return new WaitPeriodView(productWaitingPeriod.id, productWaitingPeriod.endDate, productWaitingPeriod.conditionType,
			productWaitingPeriod.conditionTypeId, productWaitingPeriod.policyId, productWaitingPeriod.waitAmountDays,
			!!overrideWaitingPeriod, overrideWaitingPeriod?.waitAmountDays, overrideWaitingPeriod?.endDate);
	});
}

function _getPaymentAccountDetails ({ bankName, sortCode, accountNumber }) {
	return [
		bankName,
		sortCode,
		accountNumber,
	].filter(Boolean)
		.join(', ');
}

export function getSystemDeductionsTotal (systemDeductions, rebalanceExcess, locale) {
	const total = systemDeductions.reduce((acc, curr) => acc + curr.amount, 0);
	return `${rebalanceExcess && total < 0 ? '' : '-'}${currency(Math.abs(total), locale)}`;
}

function _updateParentClaimWithLocalChanges (claim, parentLocalClaim, locale) {
	if (parentLocalClaim.basic) {
		claim.basic = {
			description: parentLocalClaim.basic.description,
			dateOfLoss: shortDate(parentLocalClaim.basic.dateOfLoss),
			dateOfDeath: shortDate(parentLocalClaim.basic.dateOfDeath),
			treatmentStartDate: shortDate(parentLocalClaim.basic.treatmentStart),
			treatmentEndDate: shortDate(parentLocalClaim.basic.treatmentEnd),
			amount: currency(parentLocalClaim.basic.amount, locale),
			conditions: parentLocalClaim.conditions.map(cond => ({
				ailment: cond.selectedAilment,
				bodyPart: cond.selectedBodyPart,
				conditionType: cond.selectedConditionType,
			})),
			vets: parentLocalClaim.vets,
			payeeTypes: parentLocalClaim.payees.map(payee => payee.id),
		};
	}

	if (parentLocalClaim.claimItems) {
		claim.claimItems = parentLocalClaim.claimItems.map(claimItem => {
			const systemDeductions = claimItem.systemDeductions.map(({ key, value }) => {
				return {
					description: key,
					amount: value,
				};
			});
			return {
				...claimItem,
				claimItemDeductions: claimItem.deductions,
				policySectionDescription: claimItem.policySectionName,
				treatmentPeriod: `${shortDate(claimItem.treatmentPeriod.start)} - ${shortDate(claimItem.treatmentPeriod.end)}`,
				amount: currency(claimItem.amount, locale),
				userDeductionsTotal: `-${currency(claimItem.userDeductions.reduce((acc, curr) => acc + curr.amount, 0), locale)}`,
				systemDeductions,
				systemDeductionsTotal: getSystemDeductionsTotal(systemDeductions, claimItem.rebalanceExcess, locale),
				totalAmount: currency(claimItem.totalAmount, locale),
				nonFinancialNumber: claimItem.nonFinancialNumber || 'N/A',
			};
		});
	}

	if (parentLocalClaim.recipients?.length) {
		const total = parentLocalClaim.total;
		const totalAmount = parentLocalClaim.recipients.reduce((acc, curr) => acc + Number(curr.paymentAmount), 0);

		claim.payments.recipients = parentLocalClaim.recipients.map(item => {
			return {
				payee: item.recipient.recipientName,
				recipientType: item.recipient.recipientType === PayeeType.PolicyHolder ? 'Policy Holder' : 'Vet',
				paymentType: 'Claim',
				displayAmount: currency(item.paymentAmount, locale),
				paymentDetails: `${item.selectedPayment.payee} ${_getPaymentAccountDetails(item.selectedPayment)}`,
			};
		});
		claim.payments.totalAmount = currency(totalAmount, locale);
		claim.payments.total = currency(total, locale);
		claim.payments.remaining = currency(total - totalAmount, locale);
	}

	if (parentLocalClaim.exGratiaPayments?.length) {
		claim.payments.exGratiaPayments = parentLocalClaim.exGratiaPayments.map(item => {
			return {
				payee: item.recipient.recipientName,
				recipientType: item.recipient.recipientType === PayeeType.PolicyHolder ? 'Policy Holder' : 'Vet',
				paymentType: 'Ex Gratia',
				displayAmount: currency(item.paymentAmount, locale),
				paymentDetails: `${item.selectedPayment.payee} ${_getPaymentAccountDetails(item.selectedPayment)}`,
			};
		});
	}
}
