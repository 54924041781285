<template>
	<div class="steps-wrapper">
		<div class="ui-steps action-bar d-flex align-items-center justify-content-end">
			<AqStepItem
				v-if="sharedData.claimId"
				icon-class="fas fa-file-invoice"
			>
				<div class="d-flex ml-10 d-inline-block font-weight-bold">
					Claim No: {{ sharedData.claimId | claimIdFormat }}
				</div>
			</AqStepItem>
			<AqStepItem
				v-if="claimIds && claimIds.length > 0"
				icon-class="aqv-duplicate-invoices"
			>
				<aq-claims-list
					label="Invoice Duplicates"
					:claim-ids="claimIds"
				/>
			</AqStepItem>
			<div class="d-flex justify-content-end flex-grow-1">
				<aq-wizard>
					<aq-wizard-item
						:data-qa="'aqSteps_button_' + camelConverter(uiStep.title)"
						v-for="(uiStep, index) in visibleSteps"
						:key="index"
						:title="uiStep.title"
						:status="uiStep.status"
						:icon="uiStep.iconClass"
						:type="uiStep.type"
						@click="onStepClick(uiStep.stepName)"
					/>
				</aq-wizard>
			</div>
		</div>
		<fast-track-reviewed-bar
			v-if="showFastTrack"
			:claim-id="sharedData.claimId"
			:is-fast-track="sharedData.isFastTrackReviewed"
			:disabled="!isFastTrackBarEnabled"
			@complete-fast-track="onCompleteFastTrack"
			data-qa="aqSteps_checkbox_fastTrack"
		/>
		<div class="current-step">
			<slot name="banner" />
			<component
				v-if="sharedData"
				ref="currentStepComponent"
				:is="currentStepName"
				:shared-data="sharedData"
				:locale="brand.brandLocale"
				:brand-id="brand.brandId"
				:multicondition="brand.multicondition"
			/>
		</div>
	</div>
</template>

<script>
import statusEnum from '@commonServices/models/steps/StepStatusEnum';
import { camelCase } from '@commonServices/utils/filters';
import { mapActions } from 'vuex';
import FastTrackReviewedBar from '@commonView/ClaimPage/FastTrackReviewedBar';
import { claimNumberStatus } from '@commonServices/models/ClaimStatusActionEnum';
import AqClaimsList from '@commonWidgets/AqClaimsList';
import AqStepItem from './AqStepItem.vue';

export default {
	name: 'AqSteps',
	components: {
		AqClaimsList,
		FastTrackReviewedBar,
		AqStepItem,
	},
	props: {
		steps: {
			type: Array,
			required: true,
		},
		sharedData: {
			type: Object,
			required: true,
		},
		brand: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data () {
		return {
			currentStep: undefined,
			innerSteps: undefined,
		};
	},
	created () {
		this.innerSteps = [];
		this.steps.forEach(x => {
			this.innerSteps.push(x);
		});
		this.currentStep = this.innerSteps.find(x => x.status === statusEnum.PROCESS);
		if (!this.currentStep) {
			this.currentStep = this.innerSteps[0];
		}
	},
	computed: {
		visibleSteps () {
			return this.innerSteps.filter(x => x.isVisible);
		},
		currentStepName: function () {
			if (this.currentStep)	return this.currentStep.stepName;
			return '';
		},
		claimIds () {
			return this.sharedData.multiSubmissionClaimIds;
		},
		showFastTrack () {
			return this.sharedData.claimStatus !== claimNumberStatus.New;
		},
		isFastTrackBarEnabled () {
			return !this.sharedData.isReassessment && this.sharedData.claimStatus === claimNumberStatus.Draft && !this.sharedData.isFastTrackReviewed && this.$can.FlagFastTrackReviewed;
		},
	},
	methods: {
		...mapActions(['changeCurrentClaimStepName']),
		async goNext (isPassed = true) {
			const currentIndex = this.innerSteps.findIndex((step) => step.stepName === this.currentStepName);
			if (currentIndex > -1 && currentIndex < this.innerSteps.length - 1) {
				const afterCurrent = this.innerSteps.slice(currentIndex + 1);
				const nextStep = afterCurrent.find(x => x.isVisible);
				if (nextStep) {
					if (this.$refs.currentStepComponent.beforeLeaveHandler) {
						this.$refs.currentStepComponent.beforeLeaveHandler(true, nextStep.stepName)
							.then(() => {
								this.innerSteps[currentIndex].isPassed = isPassed;
								this.currentStep = nextStep;
							});
					} else {
						this.innerSteps[currentIndex].isPassed = isPassed;
						this.currentStep = nextStep;
					}
				}
			}
		},
		async goPrevious () {
			const currentIndex = this.innerSteps.findIndex((step) => step.stepName === this.currentStepName);
			if (currentIndex > 0) {
				const beforeCurrent = this.innerSteps.slice(0, currentIndex).reverse();
				const previousStep = beforeCurrent.find(x => x.isVisible);
				if (previousStep) {
					if (this.$refs.currentStepComponent.beforeLeaveHandler) {
						this.$refs.currentStepComponent.beforeLeaveHandler(false, previousStep.stepName)
							.then(() => {
								this.currentStep = previousStep;
							});
					} else {
						this.currentStep = previousStep;
					}
				}
			}
		},
		goTo (stepName) {
			const potentialStep = this.getStepByName(stepName);
			if (potentialStep && potentialStep.status !== statusEnum.WAIT) {
				if (this.$refs.currentStepComponent.beforeLeaveHandler) {
					const isNextStep = this.innerSteps.indexOf(this.currentStep) < this.innerSteps.indexOf(potentialStep);
					this.$refs.currentStepComponent.beforeLeaveHandler(isNextStep, potentialStep.stepName)
						.then(() => {
							this.currentStep = potentialStep;
						});
				} else {
					this.currentStep = potentialStep;
				}
			}
		},
		async exitClaim () {
			await this.$nextTick(); // 'break' sync execution to allow all reactive properties be updated before leave handler call
			return this.$refs.currentStepComponent.beforeLeaveHandler(false, null)
				.then(() => {
					this.$router.back();
				});
		},
		changeVisibility (stepName, isVisible) {
			const step = this.getStepByName(stepName);
			step.isVisible = isVisible;
		},
		getStepByName (stepName) {
			return this.innerSteps.find(x => x.stepName === stepName);
		},
		onStepClick (stepName) {
			if (this.currentStep.stepName !== stepName) {
				this.goTo(stepName);
			}
		},
		getStepData (stepName) {
			const step = this.getStepByName(stepName);
			if (step) return step;
			return null;
		},
		updateClaimStatus (status) {
			this.$emit('update-status', status);
		},
		camelConverter (name) {
			return camelCase(name);
		},
		onCompleteFastTrack () {
			this.sharedData.isFastTrackReviewed = true;
		},
	},
	watch: {
		currentStep (nextValue, previousValue) {
			if (previousValue) {
				const currentIndex = this.innerSteps.findIndex((step) => step.stepName === this.nextValue);
				const beforeCurrent = this.innerSteps.slice(0, currentIndex);
				const afterCurrent = this.innerSteps.slice(currentIndex + 1);
				beforeCurrent.forEach(x => {
					if (x.isVisible)	x.status = statusEnum.SUCCESS;
				});
				afterCurrent.forEach(x => {
					if (x.isVisible) x.status = x.isPassed ? statusEnum.SUCCESS : statusEnum.WAIT;
				});
				nextValue.status = statusEnum.PROCESS;
			}
		},
		currentStepName (nextStepName) {
			this.changeCurrentClaimStepName(nextStepName);
		},
	},
	provide () {
		return {
			goNext: this.goNext,
			goPrevious: this.goPrevious,
			goTo: this.goTo,
			exitClaim: this.exitClaim,
			changeVisibility: this.changeVisibility,
			getStepData: this.getStepData,
			updateClaimStatus: this.updateClaimStatus,
			locale: this.locale,
		};
	},
	beforeDestroy () {
		this.changeCurrentClaimStepName(null);
	},
};
</script>

<style lang="scss" scoped>
  .ui-steps {
    height: 57px;
    margin-top: 1px;
    gap: 5px;
  }
</style>
