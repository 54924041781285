import httpClient from './httpClient';

export default {
	searchCounty (searchTerm) {
		return httpClient(`/api/claims/counties?searchTerms=${searchTerm}`, { omitSpinner: true })
			.get();
	},
	getCountries () {
		return httpClient('/api/claims/countries/all')
			.get();
	},
	searchCountry (searchTerm) {
		return httpClient(`/api/claims/countries?searchTerms=${searchTerm}`, { omitSpinner: true })
			.get();
	},
	getFraudCheckReasons () {
		return httpClient('/api/fraudcheck/reasons/')
			.get();
	},
	getClaimFraudCheckReasons (claimId) {
		return httpClient(`/api/fraudcheck/${claimId}/reasons/`)
			.get();
	},
	getData (filterTypes) {
		return httpClient('/api/claims/dictionary/filters')
			.post(filterTypes);
	},
	getBodyParts () {
		return httpClient('/api/claims/bodyparts')
			.get();
	},
	getReassessmentReasons () {
		return httpClient('/api/reassessment/reasons')
			.get();
	},
	getReassessmentOutcomes (claimId, outcomeState) {
		return httpClient(`/api/reassessment/${claimId}/outcomes?outcomeState=${outcomeState}`)
			.get();
	},
	getLossTypes () {
		return httpClient('/api/claims/ailment/loss-types')
			.get();
	},
	getPreXReasons () {
		return httpClient('/api/claims/pets/pre-existing-conditions/document-commentary-reasons', { omitSpinner: true })
			.get();
	},
	getEscalateReasons () {
		return httpClient('/api/claims/escalatereasons')
			.get();
	},
	getFastTrackReasons () {
		return httpClient('/api/claims/fast-track/reasons')
			.get();
	},
	searchProduct (searchTerm) {
		return httpClient(`/api/claims/products/search?searchTerms=${searchTerm}`, { omitSpinner: true })
			.get();
	},
	searchBreed (searchTerm, petTypeId) {
		const breedTerm = petTypeId ? `&petTypeId=${petTypeId}` : '';
		return httpClient(`/api/claims/breeds/search?searchTerms=${searchTerm}${breedTerm}`, { omitSpinner: true })
			.get();
	},
	addUsageReport (usageType) {
		return httpClient('/api/claims/usage', { omitSpinner: true })
			.post(usageType);
	},
};
