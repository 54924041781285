<template>
	<aq-table-popover
		class="d-flex"
		:disabled="!showPopup"
		:open="showPopup"
		boundaries-element="v-popover"
		@hide="paidTransactions = []"
	>
		<table aria-label="Payment Details">
			<tr>
				<th />
				<th>Method</th>
				<th>Amount</th>
				<th>Transaction Id</th>
				<th>Transaction Date</th>
			</tr>
			<tbody>
				<tr
					v-for="transaction of paidTransactions"
					:key="transaction.claimPaymentId"
				>
					<td>
						<component
							:is="getTransactionIcon(transaction)"
							class="icon-small mr-15"
							v-tooltip="transaction.payee"
						/>
					</td>
					<td>
						{{ getPaymentMethod(transaction) }}
					</td>
					<td>
						{{ transaction.grossAmount | currency(locale) }}
					</td>
					<td>
						{{ getTransactionData(transaction).transactionId }}
					</td>
					<td>
						{{ getTransactionData(transaction).transactionDate }}
					</td>
				</tr>
			</tbody>
		</table>
		<template slot="title">
			<div class="d-flex align-items-center text-truncate">
				<div class="mr-15 icon-wrapper">
					<template v-if="payments.length">
						<component
							:is="getPaymentPopoverIcon()"
							class="icon-small mr-5 cursor-pointer"
							v-tooltip="payments.length === 1 ? payments[0].paymentRecipient : null"
						/>
						<div
							class="claim-link"
							@click="getPaymentTransactions"
						>
							{{ 'payment' | pluralize(payments.length, true) }}
						</div>
					</template>
				</div>
			</div>
		</template>
	</aq-table-popover>
</template>

<script>
import { PolicyHolder, Vet, MultiPayments } from '@assets/icons';
import { shortDateTime } from '@commonServices/utils/filters';
import AqTablePopover from '@/clients/clientCommon/components/widgets/AqTablePopover';
import ClaimService from '@commonServices/claimService';
import { PaymentMethodBillingDisplayMap } from '@commonServices/models/PaymentMethod';
import { PaymentStatus } from '@commonServices/models/PaymentStatus';

export default {
	name: 'ClaimPaymentsDetails',
	components: {
		MultiPayments,
		AqTablePopover,
	},
	data () {
		return {
			paidTransactions: [],
		};
	},
	props: {
		claimId: {
			type: Number,
			required: true,
		},
		payments: {
			type: Array,
			required: false,
			default: () => [],
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	methods: {
		getTransactionIcon (transaction) {
			const isCustomer = this.payments.find(payment => payment.id === transaction.claimPaymentId).isCustomer;
			return this.getPaymentIcon(isCustomer);
		},
		getPaymentPopoverIcon () {
			if (this.payments.length > 1) {
				return MultiPayments;
			}
			return this.getPaymentIcon(this.payments[0].isCustomer);
		},
		getPaymentIcon (isCustomer) {
			return isCustomer ? PolicyHolder : Vet;
		},
		getPaymentTransactions () {
			ClaimService.getPaymentTransactions(this.claimId)
				.then(data => {
					this.paidTransactions = data.filter(t => (t.paymentStatus === PaymentStatus.Paid || t.paymentStatus === PaymentStatus.StoppedAndReissued));
				});
		},
		getPaymentMethod (transaction) {
			return PaymentMethodBillingDisplayMap[transaction.paymentMethod];
		},
		getTransactionData (transaction) {
			return {
				transactionId: transaction.lastInstruction?.transactionId || 'N/A',
				transactionDate: (transaction.lastInstruction ? shortDateTime(transaction.lastInstruction.paymentIssueDate) : null) || 'N/A',
			};
		},
	},
	computed: {
		showPopup () {
			return this.paidTransactions.length > 0;
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-small {
  fill: var(--cardIconSVG);
  transform: scale(0.7);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
