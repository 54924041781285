import pluralizeUtil from 'pluralize';
import { DateFormats, getCurrencyByLocale } from '@commonServices/settings/localeSettings';
import { fromStringToDate, diffInMonths, formatDate, isDate, getDayStart, diffInWeeks } from './dateUtils';

function innerDateFormat (value, dateFormat) {
	if (!value) return '';
	return formatDate(isDate(value) ? value : fromStringToDate(value), dateFormat);
}

export function longDate (value) {
	return innerDateFormat(value, DateFormats.LongDate);
}

export function shortDate (value) {
	return innerDateFormat(value, DateFormats.ShortDate);
}

export function shortDateTime (value) {
	return innerDateFormat(value, DateFormats.ShortDateTime);
}

export function shortDateTimeDay (value) {
	return innerDateFormat(value, DateFormats.ShortDateTimeDay);
}

export function currency (value, locale = null, skipSymbol = false) {
	if (typeof value !== 'number') {
		value = parseFloat(value);
	}
	const currency = getCurrencyByLocale(locale);
	let formatted = isNaN(value) ? '' : Math.abs(value).toFixed(currency.precision);
	if (parseFloat(formatted) === 0) { // handle -0.0[currency.precision] case after formatting
		formatted = parseFloat(formatted).toFixed(currency.precision);
	}
	if (formatted) {
		if (!skipSymbol) {
			formatted = `${currency.prefix ? currency.symbol : ''}${formatted}${currency.prefix ? '' : currency.symbol}`;
		}
		return `${value < 0 ? '-' : ''}${formatted}`;
	} else return '';
}

export function age (strValue) {
	const todaysDate = getDayStart();
	const monthsDiff = diffInMonths(fromStringToDate(strValue), todaysDate);
	if (monthsDiff === 0) {
		const weekDiff = diffInWeeks(fromStringToDate(strValue), todaysDate);
		return weekDiff && pluralizeUtil('Week', Math.abs(weekDiff), true);
	}
	return diffTwoDates(strValue, todaysDate);
}

export function diffTwoDates (date1, date2) {
	const initialDate = fromStringToDate(date1);
	const finalDate = fromStringToDate(date2);
	const monthsDiff = diffInMonths(finalDate, initialDate);
	const years = Math.floor(monthsDiff / 12);
	const months = monthsDiff % 12;
	return [years && pluralizeUtil('Year', years, true), (months || years === 0) && pluralizeUtil('Month', months, true)]
		.filter(Boolean).join(' ');
}

export function pad (value, width) {
	if (!isNaN(value)) {
		value = value + '';
		return value.length >= width ? value : new Array(width - value.length + 1).join('0') + value;
	} else return value;
}
export function claimIdFormat (claimId) {
	return pad(claimId, 6);
}

export function capitalizeLetter (value) {
	if (value) return value.charAt(0).toUpperCase();
	return value;
}

export function capitalize (value) {
	if (value) return value[0].toUpperCase() + value.slice(1);
	return value;
}

export function camelCase (value) {
	const regex = /\s+(\w)?/gi;

	const output = value.toLowerCase().replace(regex, function (match, letter) {
		return letter.toUpperCase();
	});
	return output;
}

export function pluralize (value, count = 1, inclusive = false) {
	if (value) return pluralizeUtil(value, count, inclusive);
	return value;
}

export function formatBytes (bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function limitCount (value) {
	return value > 99 ? '99+' : value;
}

export function limitCountTooltip (value) {
	return value > 99 ? value.toString() : '';
}

export function roundCurrency (value, roundFunc = Math.round) {
	if (!value) {
		return 0;
	}
	const preRounded = Math.round(value * 1000) / 1000; // helps with lost percision  i.e. 33.90 / 5 = 6.779999999999999, although its actually 6.78
	return roundFunc(preRounded * 100) / 100;
}
